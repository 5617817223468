import React from 'react';
import {graphql} from 'gatsby';

import ContentfulCmsPage from "../components/ContentfulCmsPage";

export default function ContentfulShopPage({data, pageContext}) {
    if (pageContext.ontId === undefined) {
        return null;
    }

    const page = data.contentful.shopPageCollection.items[0];

    return <ContentfulCmsPage page={page} />;
}


export const query = graphql`
    query shopPageCollection($ontId: String){
        contentful {
            shopPageCollection: shopPageCollection(where:{ontIdentifier: $ontId} limit: 1) {
                items{
                    title
                    body
                    shopNoticesCollection {
                        items {
                            body
                        }
                    }
                    shopCardsCollection {
                        items {
                            title
                            body
                            callToActionText
                            callToActionUrl
                            image {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`
