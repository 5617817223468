import React from 'react';
import Markdown from 'react-markdown';

const ContentfulCmsNotice = ({ body }) => {
    return (
        <div className="notice">
            <div className="notice__description">
                <Markdown source={body} />
            </div>
            <style jsx>{`
                .container {
                  background-color: white;
                }
            `}</style>
        </div>
    )
};

export default ContentfulCmsNotice;
