import React, { useEffect, useState } from 'react';
import ContentfulCmsCard from "../ContentfulCmsCard/contentfulCmsCard";
import Markdown from 'react-markdown';
import ContentfulCmsNotice from "../ContentfulCmsNotice/contentfulCmsNotice";

const ContentfulCmsPage = ({page}) => {
    const cards = page.shopCardsCollection.items;
    const notices = page.shopNoticesCollection.items;

    return (
        <>
            <h2 className="heading--two">{page.title}</h2>

            {
                notices.map((notice) =>
                    <ContentfulCmsNotice
                        body={notice.body}
                    />
                )
            }

            <p><Markdown source={page.body} /></p>

            <div className="grid">
                {
                    cards.map((card) =>
                        <div className="col col-6 col-md">
                            <ContentfulCmsCard
                                key={card.title}
                                title={card.title}
                                body={card.body}
                                cta_text={card.callToActionText}
                                cta_url={card.callToActionUrl}
                                image_url={card.image.url}
                            />
                        </div>
                    )
                }
            </div>
        </>
    )
};

export default ContentfulCmsPage;
