import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

const ContentfulCmsCard = props => {
    return (
        <Link className="card card--icon" to={props.cta_url}>
            <span className="card__image">
                <img src={props.image_url} />
            </span>
            <span className="card__content">
                <span className="card__heading">{props.title}</span>
                <span className="card__description">{props.body}</span>
                <span className="button button--primary">{props.cta_text}</span>
            </span>
        </Link>
    )
};

export default ContentfulCmsCard;
